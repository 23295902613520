import "./searchItem.scss";
import icon1 from "../../../assets/images/icon1.png";
import * as config from "../../../config";

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

function SearchItem({ searchWord, item, clickItem }) {
  const CheckSearchWord = () => {
    const styledName = item.name.replace(new RegExp(escapeRegExp(searchWord), "i"), `<span style="color: #4285f4">$&</span>`);
    const inventoryType = config.INVENTORY_TYPE[item.inventoryType];
    const replacedHtml = `<span style="color: #666">${inventoryType}</span> ${styledName}`;
    return <p dangerouslySetInnerHTML={{ __html: replacedHtml }} />;
  };

  return (
    <div className="search-item" onClick={(event) => clickItem(item, event)}>
      <div className="image-box">
        <img src={item.iconUrl} alt="selected item" />
      </div>
      <div className="content-box">
        <CheckSearchWord />
        <div>
          <div>
            <img src={icon1} alt="1st value icon" />
            <span>{item.prices[0].cost}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SearchItem;
