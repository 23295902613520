const listOfSearchedGoodsDummy = {
  statusCode: 200,
  jsonResult: {
    hasMore: false,
    data: [
      {
        iconUrl: "https://cdn-store.leagueoflegends.co.kr/images/v2/champion-tiles/10000.jpg",
        inventoryType: "CHAMPION",
        itemId: 10,
        name: "케일 lskdjflskdjflskdjslkdjfslkdf sdlkfjsldfjslkdfjsldjflskd",
        prices: [
          { cost: 450, currency: "IP" },
          { cost: 260, currency: "RP" },
        ],
        releaseDate: "2010-07-13T09:00:00.000+0900",
        storeUrl: "https://store.leagueoflegends.co.kr/champions/10",
      },
      {
        iconUrl: "https://cdn-store.leagueoflegends.co.kr/images/v2/champion-tiles/10024.jpg",
        inventoryType: "CHAMPION",
        itemId: 10024,
        name: "용 사냥꾼 케일",
        prices: [{ cost: 1350, currency: "RP" }],
        releaseDate: "2010-07-13T09:00:00.000+0900",
        storeUrl: "https://store.leagueoflegends.co.kr/skins/10024",
      },
      {
        iconUrl: "https://cdn-store.leagueoflegends.co.kr/images/v2/champion-tiles/10008.jpg",
        inventoryType: "CHAMPION",
        itemId: 10008,
        name: "강철의 심판관 케일",
        prices: [{ cost: 1350, currency: "RP" }],
        releaseDate: "2010-07-13T09:00:00.000+0900",
        storeUrl: "https://store.leagueoflegends.co.kr/skins/10008",
      },
      {
        iconUrl: "https://cdn-store.leagueoflegends.co.kr/images/v2/champion-tiles/10015.jpg",
        inventoryType: "CHAMPION",
        itemId: 10015,
        name: "초능력특공대 케일",
        prices: [{ cost: 1350, currency: "RP" }],
        releaseDate: "2010-07-13T09:00:00.000+0900",
        storeUrl: "https://store.leagueoflegends.co.kr/skins/10015",
      },
      {
        iconUrl: "https://cdn-store.leagueoflegends.co.kr/images/v2/champion-tiles/10000.jpg",
        inventoryType: "CHAMPION",
        itemId: 10,
        name: "케일",
        prices: [
          { cost: 450, currency: "IP" },
          { cost: 260, currency: "RP" },
        ],
        releaseDate: "2010-07-13T09:00:00.000+0900",
        storeUrl: "https://store.leagueoflegends.co.kr/champions/10",
      },
      {
        iconUrl: "https://cdn-store.leagueoflegends.co.kr/images/v2/champion-tiles/10024.jpg",
        inventoryType: "CHAMPION",
        itemId: 10024,
        name: "용 사냥꾼 케일",
        prices: [{ cost: 1350, currency: "RP" }],
        releaseDate: "2010-07-13T09:00:00.000+0900",
        storeUrl: "https://store.leagueoflegends.co.kr/skins/10024",
      },
      {
        iconUrl: "https://cdn-store.leagueoflegends.co.kr/images/v2/champion-tiles/10008.jpg",
        inventoryType: "CHAMPION",
        itemId: 10008,
        name: "강철의 심판관 케일",
        prices: [{ cost: 1350, currency: "RP" }],
        releaseDate: "2010-07-13T09:00:00.000+0900",
        storeUrl: "https://store.leagueoflegends.co.kr/skins/10008",
      },
      {
        iconUrl: "https://cdn-store.leagueoflegends.co.kr/images/v2/champion-tiles/10015.jpg",
        inventoryType: "CHAMPION",
        itemId: 10015,
        name: "초능력특공대 케일",
        prices: [{ cost: 1350, currency: "RP" }],
        releaseDate: "2010-07-13T09:00:00.000+0900",
        storeUrl: "https://store.leagueoflegends.co.kr/skins/10015",
      },
    ],
  },
};

export default listOfSearchedGoodsDummy;
