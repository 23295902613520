import { atom } from "recoil";

const routeState = atom({
  key: "routeState",
  default: {
    path: "/",
    parameters: null
  },
});

export default routeState;
