const listOfYourShopDummy = {
  statusCode: 200,
  jsonResult: {
    offers: [
      {
        imageUrl: "https://cdn-store.leagueoflegends.co.kr/images/v2/champion-splashes/84002.jpg",
        name: "지옥의 아칼리1",
        discountPrice: 918,
        originalPrice: 1310,
        storeUrl: "https://store.leagueoflegends.co.kr/yourshop",
      },
      {
        imageUrl: "https://cdn-store.leagueoflegends.co.kr/images/v2/champion-splashes/84002.jpg",
        name: "지옥의 아칼리2",
        discountPrice: 917,
        originalPrice: 1310,
        storeUrl: "https://store.leagueoflegends.co.kr/yourshop",
      },
      {
        imageUrl: "https://cdn-store.leagueoflegends.co.kr/images/v2/champion-splashes/84002.jpg",
        name: "지옥의 아칼리3",
        discountPrice: 917,
        originalPrice: 1310,
        storeUrl: "https://store.leagueoflegends.co.kr/yourshop",
      },
      {
        imageUrl: "https://cdn-store.leagueoflegends.co.kr/images/v2/champion-splashes/84002.jpg",
        name: "지옥의 아칼리4",
        discountPrice: 917,
        originalPrice: 1310,
        storeUrl: "https://store.leagueoflegends.co.kr/yourshop",
      },
      {
        imageUrl: "https://cdn-store.leagueoflegends.co.kr/images/v2/champion-splashes/84002.jpg",
        name: "지옥의 아칼리5",
        discountPrice: 917,
        originalPrice: 1310,
        storeUrl: "https://store.leagueoflegends.co.kr/yourshop",
      },
    ],
  },
};

export default listOfYourShopDummy;
