import ExecuteButton from "../../common/ExecuteButton";
import { useSetRecoilState } from "recoil";
import routeState from "../../../store/atoms/routeState";
import http from "../../../httpModules/http";
import "./requestPage.scss";
import { useState, useEffect } from "react";
import LoadingBar from "../../common/LoadingBar";

function RequestPage({ config, parameters, path, previous }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const $setRouteState = useSetRecoilState(routeState);

  useEffect(() => {
    if (isError) {
      $setRouteState({ path: "/error_default" });
    }
  }, [isError]);

  const checkIsRequesting = () => path === "/rp_request";
  const requestButtonExecutorForNext = async () => {
    setIsLoading(true);
    try {
      console.log("parameters", parameters);
      const result = await http.setRP(false, parameters.item.price);
      console.log("RequestPage->http.setRP", result);
      const isGranted = result.jsonResult.granted;
      const isReason = result.jsonResult?.reason;
      if (isGranted) {
        setIsLoading(false);
        $setRouteState({
          path: "/rp_succeed",
          parameters: {
            item: parameters.item,
          },
        });
      } else {
        setIsLoading(false);
        if (isReason === "OVER") {
          $setRouteState({
            path: "/error_request_enough_rp",
          });
        } else {
          $setRouteState({
            path: "/error_request_lack_rp",
          });
        }
      }
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
      console.error(error);
    }
  };
  const successButtonExecutorForNext = () => {
    window.location.href = parameters.item.storeUrl;
  };
  const buttonExecutorForPrevious = () => {
    $setRouteState({
      path: previous,
      parameters: {
        searchWord: parameters?.searchWord,
      },
    });
  };

  if (isLoading) {
    return <LoadingBar />;
  }

  return (
    <>
      <p className="common-title">{config.TITLE}</p>
      <div className="common-content">
        <p className="common-description">{config.DESCRIPTION}</p>
        <div className="img-box">
          <img src={parameters.item.imageUrl} alt="item icon" className="img" />
        </div>
        <p className="request-item-name">{parameters.item.name}</p>
      </div>
      <div className="btn-box">
        {checkIsRequesting() && (
          <ExecuteButton classType="subBtn" buttonTitle="이전" excutor={buttonExecutorForPrevious} isDisabled={false} />
        )}
        <ExecuteButton
          classType="mainBtn"
          buttonTitle={config.MAIN_BTN}
          excutor={checkIsRequesting() ? requestButtonExecutorForNext : successButtonExecutorForNext}
          isDisabled={false}
        />
      </div>
    </>
  );
}

export default RequestPage;
