export const INTRO = {
  TITLE: "RP가 조금 부족해요!",
  DESCRIPTION:
    "임의로 RP를 지급해 드리는 것은 원칙상 불가능하지만, 아주 적은 RP가 부족하여 원하는 상품을 구매할 수 없는 경우 30RP 이하라면 계정당 1회에 한하여 지원해드리고 있습니다. \n\n 단, RP지원은 계정당 단 한 번만 도움을 드리고 있으며, 이후에는 도움을 드릴 수 없으니 참고 부탁드립니다. \n\n 아래의 버튼을 눌러 부족한 RP를 신청할 수 있습니다.",
  MAIN_BTN: "RP를 신청합니다.",
};
export const RP_REQUEST = {
  TITLE: "RP를 신청할까요?",
  DESCRIPTION: `다음 버튼을 누르시면, 아래 상품을 구입하기 위해 부족한 RP가 지급됩니다. 지급 후에는 취소 또는 변경이 불가능하니 신중하게 신청해주세요.`,
  MAIN_BTN: "다음",
};
export const RP_SUCCEED = {
  TITLE: "RP 지급 완료",
  DESCRIPTION: `이제 아래 상품을 구입할 수 있는 충분한 RP를 보유하고 있습니다.`,
  MAIN_BTN: "지금 구입하러 가기 ",
};
export const ERROR_ELIGIBILITY_NO_ITEMS = {
  TITLE: "구매하실 수 있는 상품이 없습니다.",
  DESCRIPTION: `상품 구매를 위한 최소한의 RP를 보유하고 있지 않습니다. 최소한의 RP를 충전하신 후에 다시 시도하여 주시기 바랍니다. `,
  MAIN_BTN: "돌아가기",
  PATH: "/",
};
export const ERROR_ELIGIBILITY_TAKEN = {
  TITLE: "이미 지급받은 이력이 있습니다.",
  DESCRIPTION: "플레이어님께서는 이전에 이 계정에서 RP를 지급받으셨습니다.",
  MAIN_BTN: "돌아가기",
  PATH: "/",
};
export const ERROR_MY_SHOP_NO_ITEM = {
  TITLE: "나만의 상점",
  DESCRIPTION:
    "나만의 상점에 표시된 상품이 없습니다.\n 나만의 상점을 아직 열지 않았거나\n 나만의 상점 운영 기간이 아닙니다. ",
  MAIN_BTN: "돌아가기",
  PATH: "/search",
};
export const ERROR_REQUEST_LACK_RP = {
  TITLE: "RP가 부족합니다.",
  DESCRIPTION:
    "상품 구매를 위한 최소한의 RP를 보유하고 있지 않습니다. 최소한의 RP를 충전하신 후에 다시 시도하여 주시기 바랍니다.",
  MAIN_BTN: "돌아가기",
  PATH: "/search",
};
export const ERROR_REQUEST_ENOUGH_RP = {
  TITLE: "RP가 이미 충분합니다.",
  DESCRIPTION:
    "상품 구매를 위한 충분한 RP를 이미 보유하고 있습니다. 다른 상품을 선택하시거나, 추후에 다시 시도하여 주시기 바랍니다.",
  MAIN_BTN: "돌아가기",
  PATH: "/search",
};
export const ERROR_DEFAULT = {
  TITLE: "에러가 발생했습니다.",
  DESCRIPTION: "예기치 못한 에러가 발생했습니다. \n 처음으로 돌아가 다시 시도해 주시기 바랍니다.",
  MAIN_BTN: "돌아가기",
  PATH: "/",
};
export const INVENTORY_TYPE = {
  WARD_SKIN: "와드",
  BOOST: "부스트",
  TFT_DAMAGE_SKIN: "펑펑 효과",
  TFT_MAP_SKIN: "결투장 스킨",
  TEAM_SKIN_PURCHASE: "계정",
  SPELL_BOOK_PAGE: "룬페이지",
  FANPASS: "팬 패스",
  BUNDLES: "세트",
  TEAMPASS: "팀 패스",
  HEXTECH_CRAFTING: "마법공학 재료",
  EMOTE: "감정표현",
  SUMMONER_ICON: "아이콘",
  EVENT_PASS: "이벤트 패스",
  CHAMPION_SKIN: "스킨",
  COMPANION: "꼬마 전설이",
  RECOLOR: "크로마",
  CHAMPION: "챔피언",
  RP: "RP 충전",
  RPGIFT: "RP 선물",
};
