import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import ExecuteButton from "../../common/ExecuteButton";
import routeState from "../../../store/atoms/routeState";
import http from "../../../httpModules/http";
import "./myShopPage.scss";
import LoadingBar from "../../common/LoadingBar";
import icon1 from "../../../assets/images/icon1.png";

function MyShopPage() {
  const [isDisabled, setIsDisabled] = useState(true);
  const [isError, setIsError] = useState(false);
  const [myShopList, setMyShopList] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const $setRouteState = useSetRecoilState(routeState);

  useEffect(() => {
    (async () => {
      try {
        const result = await http.getListOfYourShop(false);
        console.log("MyShopPage->http.getListOfYourShop", result);
        setMyShopList(result);
      } catch (error) {
        setIsError(true);
        console.error(error);
      }
    })();
  }, []);

  useEffect(() => {
    if (isError) {
      $setRouteState({ path: "/error_default" });
    }
  }, [isError]);

  const clickItem = (item, event) => {
    console.log("MyShopPage->clickItem.item", item);
    event.target.parentNode.parentNode.childNodes.forEach((element) => {
      let clickPoint = element.querySelector(".click-point");
      clickPoint.classList.add("opacity");
    });
    console.log(event.target);
    event.target.classList.remove("opacity");
    setIsDisabled(false);
    setSelectedItem(item);
  };

  const buttonExecutorForPrevious = () => {
    $setRouteState({
      path: "/search",
    });
  };

  const buttonExecutorForNext = () => {
    $setRouteState({
      path: "/rp_request",
      parameters: {
        item: {
          name: selectedItem.name,
          imageUrl: selectedItem.imageUrl,
          price: selectedItem.discountPrice,
          storeUrl: selectedItem.storeUrl,
        },
      },
      previous: "/my_shop",
    });
  };

  if (!myShopList) {
    return <LoadingBar />;
  }

  if (myShopList.jsonResult?.offers.length === 0) {
    $setRouteState({ path: "/error_my_shop_no_item" });
  }

  return (
    <>
      <p className="common-title">나만의 상점</p>
      <div className="common-content">
        <p className="common-description">나만의 상점에 표시된 상품 목록입니다.</p>
        <div className={myShopList.jsonResult?.offers.length > 3 ? "item-box" : "item-box h-auto"}>
          {myShopList.jsonResult?.offers.map((item) => {
            return (
              <div key={item.name} className="item-img">
                <img src={item.imageUrl} alt={item.name} />
                <div>
                  <p>{item.originalPrice}</p>
                  <div>
                    <img src={icon1} alt={"rp값"} />
                    <span>{item.discountPrice}</span>
                  </div>
                </div>
                <div
                  className="click-point"
                  onClick={(event) => {
                    clickItem(item, event);
                  }}
                />
              </div>
            );
          })}
        </div>
        {6 > myShopList.jsonResult?.offers.length > 0 && (
          <div className="unopened-porduct-box">
            <p>혹시 상품이 보이지 않나요?</p>
            <a href={process.env.REACT_APP_MY_SHOP_URL}>모바일 상점 바로가기</a>
          </div>
        )}
      </div>

      <div className="btn-box">
        <ExecuteButton classType="subBtn" buttonTitle="이전" excutor={buttonExecutorForPrevious} isDisabled={false} />
        <ExecuteButton classType="mainBtn" buttonTitle="다음" excutor={buttonExecutorForNext} isDisabled={isDisabled} />
      </div>
    </>
  );
}
export default MyShopPage;
