import { useSetRecoilState } from "recoil";
import routeState from "../../../store/atoms/routeState";
import ExecuteButton from "../../common/ExecuteButton";
import "./searchPage.scss";
import { useEffect, useState, useRef } from "react";

function SearchPage({ parameters }) {
  const $setRouteState = useSetRecoilState(routeState);
  const [searchWord, setSearchWord] = useState(parameters?.searchWord || "");
  const [isDisabled, setIsDisabled] = useState(true);
  const searchInput = useRef();

  useEffect(() => {
    if (!searchInput.current) return;
    searchInput.current.focus();
  }, []);

  const goToMyShop = (event) => {
    event.preventDefault();
    $setRouteState({ path: "/my_shop" });
  };

  const buttonExecutor = () => {
    $setRouteState({
      path: "/item_page",
      parameters: { searchWord: searchWord },
    });
  };

  const changeSearchWord = (event) => {
    setSearchWord(event.target.value);
  };

  const submitSearchInfo = (event) => {
    event.preventDefault();
    buttonExecutor();
  };

  const clickSearchBox = (event) => {
    searchInput.current.focus();
  };

  const checkTextCount = (event) => {
    const text = event.target.value;
    if (text.length >= 1) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };

  return (
    <>
      <p className="common-title">어떤 상품인가요?</p>
      <div className="common-content content-margin">
        <p className="common-description">
          스킨, 챔피언, 아이콘, 감정표현을 검색할 수 있습니다.
        </p>
        <form onSubmit={submitSearchInfo} onClick={clickSearchBox}>
          <div className={"search-box active"}>
            <label htmlFor="search">검색</label>
            <input
              type="search"
              ref={searchInput}
              value={searchWord}
              onChange={changeSearchWord}
              onKeyUp={checkTextCount}
            />
          </div>
        </form>
        <p className="my-shop-link">
          나만의 상점에 표시된 상품의 경우,
          <br />
          할인을 적용받아 구매하시려면{" "}
          <a href="/" onClick={goToMyShop}>
            여기
          </a>
          를 눌러주세요.
        </p>
      </div>
      <div className="btn-box">
        <ExecuteButton
          classType="mainBtn"
          buttonTitle="다음"
          excutor={buttonExecutor}
          isDisabled={isDisabled}
        />
      </div>
    </>
  );
}

export default SearchPage;
