import React from "react";
import { useRecoilValue } from "recoil";
import routeState from "../../../store/atoms/routeState";

function RecoilRoute({ children, path }) {
  const $routeState = useRecoilValue(routeState);
  window.scrollTo(0, 0);
  return (
    path === $routeState.path &&
    React.cloneElement(children, {
      parameters: $routeState.parameters,
      path: path,
      previous: $routeState.previous,
    })
  );
}
export default RecoilRoute;
