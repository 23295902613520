import CommonPage from "./components/pages/CommonPage";
import ErrorPage from "./components/pages/ErrorPage";
import MyShopPage from "./components/pages/MyShopPage";
import ItemPage from "./components/pages/ItemPage";
import SearchPage from "./components/pages/SearchPage";
import RequestPage from "./components/pages/RequestPage";
import RecoilRoute from "./components/common/RecoilRoute";
import * as config from "./config";
import "./app.scss";

function App() {
  return (
    <div className="app-container">
      <div className="common-container">
        <RecoilRoute path="/">
          <CommonPage config={config.INTRO} />
        </RecoilRoute>

        <RecoilRoute path="/search">
          <SearchPage />
        </RecoilRoute>

        <RecoilRoute path="/rp_request">
          <RequestPage config={config.RP_REQUEST} />
        </RecoilRoute>

        <RecoilRoute path="/rp_succeed">
          <RequestPage config={config.RP_SUCCEED} />
        </RecoilRoute>

        <RecoilRoute path="/my_shop">
          <MyShopPage />
        </RecoilRoute>

        <RecoilRoute path="/item_page">
          <ItemPage />
        </RecoilRoute>

        <RecoilRoute path="/error_eligibility_no_item">
          <ErrorPage config={config.ERROR_ELIGIBILITY_NO_ITEMS} />
        </RecoilRoute>

        <RecoilRoute path="/error_eligibility_taken">
          <ErrorPage config={config.ERROR_ELIGIBILITY_TAKEN} />
        </RecoilRoute>

        <RecoilRoute path="/error_my_shop_no_item">
          <ErrorPage config={config.ERROR_MY_SHOP_NO_ITEM} />
        </RecoilRoute>

        <RecoilRoute path="/error_request_enough_rp">
          <ErrorPage config={config.ERROR_REQUEST_ENOUGH_RP} />
        </RecoilRoute>

        <RecoilRoute path="/error_request_lack_rp">
          <ErrorPage config={config.ERROR_REQUEST_LACK_RP} />
        </RecoilRoute>

        <RecoilRoute path="/error_default">
          <ErrorPage config={config.ERROR_DEFAULT} />
        </RecoilRoute>

        <div id="app-version">
          Version <span>{process.env.REACT_APP_VERSION}</span>
        </div>
      </div>
    </div>
  );
}
export default App;
