import "./ExecuteButton.scss";

function ExecuteButton({ classType, buttonTitle, excutor, isDisabled }) {
  const clickButton = () => {
    if (isDisabled) return;
    excutor();
  };

  return (
    <div disabled={isDisabled} className={classType} onClick={clickButton}>
      {buttonTitle}
    </div>
  );
}

export default ExecuteButton;
