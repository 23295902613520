import ExecuteButton from "../../common/ExecuteButton";
import routeState from "../../../store/atoms/routeState";
import {useSetRecoilState} from "recoil";
import {useEffect, useLayoutEffect, useState} from "react";
import http from "../../../httpModules/http";
import LoadingBar from "../../common/LoadingBar";
import Cookies from "js-cookie";
import window from "global";

function CommonPage({ config }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const $setRouteState = useSetRecoilState(routeState);

  useLayoutEffect(() => {
    document.addEventListener('riotbar_authcheckcomplete', function() {
      console.log("riotbar_authcheckcomplete", window.RiotBar.account.getAuthState().isAuthenticated);
      const isAuthenticated = window.RiotBar.account.getAuthState().isAuthenticated;
      if (!isAuthenticated) {
        setIsLoading(false);
        return;
      }
      console.log("Cookie-auth", Cookies.get("auth"));
      if(Cookies.get("auth") === "checked") {
        buttonExecutor();
      } else {
        setIsLoading(false);
      }
    });
    const timer = setTimeout(() => {
      console.log("setTimeout triggered....");
      if (isLoading) setIsLoading(false);
    }, 3000);
    return () => {
      clearTimeout(timer);
    }
  }, []);

  useEffect(() => {
    if (isError) {
      $setRouteState({ path: "/error_default" });
    }
  }, [isError, $setRouteState]);

  const buttonExecutor = async () => {
    setIsLoading(true);
    try {
      const result = await http.getEligibilityOfRP(false);
      console.log("result: http.getEligibilityOfRP", window.RiotBar.account.getAuthState());
      if (result.jsonResult?.eligibility) {
        setIsLoading(false);
        $setRouteState({ path: "/search" });
      } else {
        setIsLoading(false);
        if (result.jsonResult?.reason === "TAKEN") {
          $setRouteState({ path: "/error_eligibility_taken" });
        }
        if (result.jsonResult?.reason === "NO_ITEMS") {
          $setRouteState({ path: "/error_eligibility_no_item" });
        }
      }
    } catch (error) {
      if (error.response?.status !== 401) {
        setIsError(true);
      }
    }
  };

  if (isLoading) {
    return <LoadingBar />;
  }

  return (
    <>
      <p className="common-title">{config.TITLE}</p>
      <div className="common-content content-margin">
        <p className="common-description">{config.DESCRIPTION}</p>
      </div>
      <div className="btn-box">
        <ExecuteButton classType="mainBtn" buttonTitle={config.MAIN_BTN} excutor={buttonExecutor} isDisabled={false} />
      </div>
    </>
  );
}
export default CommonPage;
