import ExecuteButton from "../../common/ExecuteButton";
import { useSetRecoilState } from "recoil";
import routeState from "../../../store/atoms/routeState";

function ErrorPage({ config }) {
  const $setRouteState = useSetRecoilState(routeState);
  const buttonExecutor = () => {
    $setRouteState({ path: config.PATH });
  };

  return (
    <>
      <p className="common-title">{config.TITLE}</p>
      <div className="common-content content-margin">
        <p className="common-description">{config.DESCRIPTION}</p>
      </div>
      <div className="btn-box">
        <ExecuteButton
          classType="mainBtn"
          buttonTitle={config.MAIN_BTN}
          excutor={buttonExecutor}
          isDisabled={false}
        />
      </div>
    </>
  );
}

export default ErrorPage;
