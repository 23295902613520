import { useEffect, useState } from "react";
import ExecuteButton from "../../common/ExecuteButton";
import "./itemPage.scss";
import http from "../../../httpModules/http";
import SearchItem from "../../common/SearchItem";
import { useSetRecoilState } from "recoil";
import routeState from "../../../store/atoms/routeState";
import LoadingBar from "../../common/LoadingBar";

function ItemPage({ parameters }) {
  const [disabled, setDisabled] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isError, setIsError] = useState(false);
  const [searchedList, setSearchedList] = useState(null);
  const $setRouteState = useSetRecoilState(routeState);

  useEffect(() => {
    (async (searchWord) => {
      try {
        const result = await http.getListOfSearchedGoods(false, searchWord);
        setSearchedList(result);
        console.log("ItemPage->http.getListOfSearchedGoods", result);
      } catch (error) {
        setIsError(true);
        console.error(error);
      }
    })(parameters.searchWord);
  }, [parameters.searchWord]);

  useEffect(() => {
    if (isError) {
      $setRouteState({ path: "/error_default" });
    }
  }, [isError]);

  const clickItem = (item, event) => {
    const searchItem = event.target.closest(".search-item");
    searchItem.parentNode.childNodes.forEach((element) => {
      element.classList.add("opacity");
    });
    searchItem.classList.remove("opacity");
    setDisabled(false);
    setSelectedItem(item);
  };
  const executorForPrevious = () => {
    $setRouteState({
      path: "/search",
      parameters: {
        searchWord: parameters.searchWord,
      },
    });
  };
  const buttonExecutorForNext = () => {
    $setRouteState({
      path: "/rp_request",
      parameters: {
        item: {
          name: selectedItem.name,
          imageUrl: selectedItem.iconUrl,
          price: selectedItem.prices.find((x) => x.currency === "RP").cost,
          storeUrl: selectedItem.storeUrl,
        },
        searchWord: parameters.searchWord,
      },
      previous: "/item_page",
    });
  };

  if (!searchedList) {
    return <LoadingBar />;
  }

  return (
    <>
      <div className="div-search-box" onClick={executorForPrevious}>
        <p>검색</p>
        <p>{parameters.searchWord}</p>
      </div>
      <div className="common-content item-page-content">
        {searchedList.jsonResult?.data.length === 0 && (
          <div className="exception-description">
            검색 결과가 없거나 너무 많습니다.
            <br />좀 더 상세한 상품명을 입력해주세요.
          </div>
        )}
        {searchedList.jsonResult?.data.map((item) => {
          return (
            <SearchItem
              key={item.itemId.toString()}
              item={item}
              clickItem={clickItem}
              searchWord={parameters.searchWord}
            />
          );
        })}
        {searchedList.jsonResult?.hasMore && (
          <div className="exception-description product-excess">
            검색 결과가 너무 많아 표시되지 않은 상품이 있습니다. 좀 더 상세한
            상품명을 입력해주세요.
          </div>
        )}
      </div>
      <div className="btn-box item-page-btn-box">
        <ExecuteButton
          classType="subBtn"
          buttonTitle="이전"
          excutor={executorForPrevious}
          isDisabled={false}
        />
        <ExecuteButton
          classType="mainBtn"
          buttonTitle="다음"
          excutor={buttonExecutorForNext}
          isDisabled={disabled}
        />
      </div>
    </>
  );
}
export default ItemPage;
