import axios from "axios";
import eligibilityOfRPDummy from "./dommies/eligibilityOfRPDummy";
import listOfSearchedGoodsDummy from "./dommies/listOfSearchedGoodsDummy";
import listOfYourShopDummy from "./dommies/listOfYourShopDummy";
import setRpDummy from "./dommies/setRPDummy";
import Cookies from "js-cookie";

const CONFIG = {
  AUTH_COOKIE: "auth",
};

const riotAuth = ((riot) => {
  const tryLogin = () => {
    riot = riot || window.RiotBar;
    riot.account.login();
  };
  const tryLoginWithAuth = () => {
    riot = riot || window.RiotBar;
    if (!riot.account.getAuthState().isAuthenticated) {
      tryLogin();
    }
  };
  return {
    tryLogin: () => {
      try {
        tryLogin();
      } catch (exception) {
        console.error(exception);
      }
    },
    tryLoginWithAuth: () => {
      try {
        tryLoginWithAuth();
      } catch (exception) {
        console.log(exception);
      }
    },
  };
})(window.RiotBar);

class Http {
  constructor() {
    this._willUseDummyData = Boolean(!!JSON.parse(process.env.REACT_APP_WILL_USE_DUMMY_DATA));
    this._$$ = axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL,
      withCredentials: Boolean(!!JSON.parse(process.env.REACT_APP_WITH_CREDENTIALS)),
      timeout: 1000 * 30,
      headers: { "Content-Type": "application/json" },
    });
    // Add a interceptor to check if signed in or not.
    this._$$.interceptors.response.use(
      (response) => {
        Cookies.remove(CONFIG.AUTH_COOKIE);
        return response;
      },
      (error) => {
        if (error.response?.status === 401) {
          Cookies.set(CONFIG.AUTH_COOKIE, "checked", { expires: 1 });
          riotAuth.tryLogin();
        } else {
          Cookies.remove(CONFIG.AUTH_COOKIE);
        }
        return Promise.reject(error);
      }
    );
    // Add a interceptor to transform data.
    this._$$.interceptors.response.use(
      (response) => {
        response.data = {
          statusCode: response.status,
          jsonResult: response.status === 200 ? response.data : null,
        };
        return response;
      },
      (error) => Promise.reject(error)
    );
  }

  getEligibilityOfRP = async (willUseDummyData) => {
    if (this._willUseDummyData && willUseDummyData) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(eligibilityOfRPDummy);
        }, 1000 * 2);
      });
    } else {
      try {
        const response = await this._$$.get("/eligibility");
        return response.data;
      } catch (error) {
        throw error;
      }
    }
  };

  getListOfSearchedGoods = async (willUseDummyData, searchWord) => {
    if (this._willUseDummyData && willUseDummyData) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(listOfSearchedGoodsDummy);
        }, 1000 * 2);
      });
    } else {
      try {
        const encodedSearchWord = encodeURIComponent(searchWord);
        console.log(encodedSearchWord);
        const response = await this._$$.get(`/search?q=${encodedSearchWord}`);
        return response.data;
      } catch (error) {
        throw error;
      }
    }
  };

  getListOfYourShop = async (willUseDummyData) => {
    if (this._willUseDummyData && willUseDummyData) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(listOfYourShopDummy);
        }, 1000 * 2);
      });
    } else {
      try {
        const response = await this._$$.get("/yourshop");
        return response.data;
      } catch (error) {
        throw error;
      }
    }
  };

  setRP = async (willUseDummyData, requestRP) => {
    console.log("requestRP", requestRP);
    if (this._willUseDummyData && willUseDummyData) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(setRpDummy);
        }, 1000 * 2);
      });
    } else {
      try {
        const response = await this._$$.post("/grant", { RP: requestRP });
        return response.data;
      } catch (error) {
        throw error;
      }
    }
  };
}

const http = new Http();
export default http;
