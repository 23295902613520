import ReactLoading from "react-loading";

function LoadingBar() {
  return (
    <div
      style={{
        marginTop: "60%",
      }}>
      <ReactLoading
        type={"bars"}
        color={"#ff4655"}
        height={"auto"}
        width={100}
      />
    </div>
  );
}
export default LoadingBar;
